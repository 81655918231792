import React, { useState } from 'react';
import 'react-big-calendar/lib/sass/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import { Footer } from '../../header'
import ReactTooltip from "react-tooltip";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectors as AppointmentsSelector, actions as AppointmentActions,
  Appointmentstate,  getAppointmentById
} from '../../store/slices/appointments'
import {  isAppointmentBookedByUser } from "../Calendar/appointmentsUtils"
import { useTranslation } from 'react-i18next';
import { selectors as CatalogSelector } from '../../store/slices/catalog'
import { selectors as ProfileSelectors } from '../../store/slices/profile'
import moment from 'moment';
import {
  Alert, Card, CardBody, CardHeader, CardFooter, CardTitle, Button,
  ButtonGroup, ButtonToolbar, Modal, ModalHeader,ModalBody, ModalFooter
} from 'reactstrap';
import { ActivityButton } from '../ActivityButton'
import { INTERACTIVE_SESSION_TYPE } from '../../store/sagas/remotesessions';
import { MdConnectedTv, MdOutlineHomeWork, MdOutlineHelp } from 'react-icons/md';
import { FaTrashAlt } from "react-icons/fa";

export const InteractiveSessionsDashboard = (props: any) => {

  const dispatch = useDispatch();
  const userAttributes = useSelector(ProfileSelectors.getProfile);
  const appointments = useSelector(AppointmentsSelector.getInteractiveSessions);
  const experiments = useSelector(CatalogSelector.getExperiments);
  //const isTeacher = useSelector(AuthSelectors.isTeacher);
  //const isStudent = useSelector(AuthSelectors.isStudent);
  const { t } = useTranslation('frontend', { useSuspense: false });
  const [appointmentToUnbook, setAppointmentToUnbook] = useState(null);
  const [isUnbookingPending, setUnbookingPending] = useState(false);

  const closeUnbookAppointmentModal = () => {
    setAppointmentToUnbook(null);
    
  }
  
  
  const unbookAppointment = (appointmentId:any) => {
    const appointment = getAppointmentById(appointments as any, appointmentId);
    if (appointment != null) {
      //console.log("SAGA sto passando da editor app:", appointment);
      dispatch(AppointmentActions.willUnbookAppointment(appointment));
      setUnbookingPending(true);
    };
  }
  

  React.useEffect(() => {
    dispatch(AppointmentActions.willLoadAppointments(
      { "start": "1900-01-20T10:00:00.000Z", "end": "2050-01-20T10:00:00.000Z", "type": INTERACTIVE_SESSION_TYPE }
    ));
    //console.log("hands-on session dashboard: called willLoadAppointments");
  }, []);

  React.useEffect(() => {
    //console.log("APPOINTMENTS UPDATED");
    setUnbookingPending(false);
  }, [appointments])


  React.useEffect(() => {
    //console.log("UNBOOKING_PENDING:", isUnbookingPending);
    if (!isUnbookingPending) setAppointmentToUnbook(null);
  }, [isUnbookingPending])

  React.useEffect(() => {
    //console.log("APPOINTMENTS UPDATED::", appointments);
    setUnbookingPending(false);
  }, [appointments])


  React.useEffect(() => {
    //console.log("UNBOOKING_PENDING:", isUnbookingPending);
    if (!isUnbookingPending) setAppointmentToUnbook(null);
  }, [isUnbookingPending])


  const getInteractiveDeviceId = (initialData: any) => {
    if (initialData?.type == null || !initialData.type.startsWith(INTERACTIVE_SESSION_TYPE)) return null;
    const isessionId = initialData.type.split(`${INTERACTIVE_SESSION_TYPE}__`)[1]
    //console.log("initialData:", initialData)
    return isessionId
  }

  const getInteractiveDeviceById = (experimentId: any, ideviceId: any) => {
    const currentExperiment = experiments[experimentId] // initialData.title
    if (currentExperiment == null) return { "titolo": "<Experiment Not found>" }
    //console.log(`IDEVICE_ROW (currentExperiment) on deviceId ${ideviceId}:`, currentExperiment)
    const result = currentExperiment["interactive_devices"]?.filter((device: any) => {
      return device["id"] == ideviceId
    })
    if (result?.length > 0) return (result[0])
    else return { "titolo": "<Device Not found>" }
  }

  const startStopFormatter = (cell: any, row: any) => {
    return moment(cell).format('DD/MM/YYYY - HH:mm');
  }

  const deviceTitleFormatter = (cell: any, row: any) => {
    const device = getInteractiveDeviceById(row["experiment_id"], getInteractiveDeviceId(row));
    //console.log("IDEVICE_ROW:", device)
    return device["titolo"]
  }

  const deviceDescriptionFormatter = (cell: any, row: any) => {
    const device = getInteractiveDeviceById(row["experiment_id"], getInteractiveDeviceId(row));
    //console.log("IDEVICE_ROW:", device)
    return device["descrizione"]
  }

  const homeworkFormatter =  (cell: any, row: any) =>{
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>

        <ButtonToolbar>
          <ButtonGroup>
            <MdOutlineHomeWork data-tip={t("Apri relazione")}
              cursor="pointer"
              size={32}
              style={{ margin: "10px" }} color={row["homeworkUrl"]!=null ? "#007bff" : "red"}
              onClick={() => {
                if (row["homeworkUrl"]!=null) {
                  const win = window.open(row["homeworkUrl"], 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=1400,height=800');
                  win!.focus();
                }


              }}>
            </MdOutlineHomeWork>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    )
  }

  const actionsFormatter = (cell: any, value: any) => {
    const deviceId = getInteractiveDeviceId(value)
    //console.log(`Passo value actionFormatter: (${deviceId})`, value);
    const deviceAvailable = moment().isBetween(moment(value["startDate"]), moment(value["stopDate"]));

    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>

        <ButtonToolbar>
          <ButtonGroup>
            <MdConnectedTv data-tip={t("Collegati al dispositivo")}
              cursor="pointer"
              size={32}
              style={{ margin: "5px" }} color={deviceAvailable ? "#007bff" : "red"}
              onClick={() => {
                if (true || deviceAvailable) {
                  const isessionUrl = `/isession/${value.organization}/${value.id}/${value.experiment_id}/${deviceId}`
                  const win = 
                  window.open(isessionUrl, 'winname', `directories=no,titlebar=no,toolbar=yes,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=${window.screen.width},height=${window.screen.height}`);
                  win!.focus();
                }
              }}>
            </MdConnectedTv>

            <MdOutlineHelp data-tip={t("Scarica la guida")}
              cursor="pointer"
              size={32}
              style={{ margin: "5px" }} color={"#007bff" }
              onClick={() => {
                  //const guideUrl = "/interactive_sessions/10002/procedura Sessione Interattiva Fotovoltaico DEF.pdf"
                  const conf = getInteractiveDeviceById(value.experiment_id, getInteractiveDeviceId(value))
                  const guideUrl = conf["attachments"]["guide"];
                  const win = 
                  window.open(guideUrl, 'winname', `directories=no,titlebar=no,toolbar=yes,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=${window.screen.width},height=${window.screen.height}`);
                  win!.focus();
              }}>
            </MdOutlineHelp>  
            <FaTrashAlt data-tip={t("Disdici")}
             cursor="pointer"
             size={28}  
             style={{ margin: "5px" , paddingTop:"5px"}} color={"red"}
             onClick={(ev:any) => {
              console.log("record value:", value);
              setAppointmentToUnbook(value.id)
            }}
            >
            </FaTrashAlt>
          </ButtonGroup>
        </ButtonToolbar>
        <ReactTooltip/>
      </div>
    )
  }

  const columns = (next: boolean) => [
    {
      dataField: 'id',
      text: 'id',
      hidden: true
    },
    {
      dataField: 'organization',
      text: 'organization',
      hidden: true
    },
    {
      dataField: 'experiment_id',
      text: 'experiment_id',
      hidden: true
    },
    {
      dataField: 'startDate',
      text: t('Inizio'),
      headerStyle: () => {
        return { width: '12%' };
      },
      formatter: startStopFormatter,
      sort: true,
      sortFunc: (a:any, b:string, order:string) => {
        const isBefore = (order=="asc") && moment(a).isBefore(moment(b)) || (order!="asc" && moment(b).isBefore(moment(a)))
        return (isBefore ? -1 : 1)
      },
    },
    {
      dataField: 'stopDate',
      text: t('Fine'),
      headerStyle: () => {
        return { width: '12%' };
      },
      formatter: startStopFormatter,
      sort: true,
      sortFunc: (a:any, b:string, order:string) => {
        const isBefore = (order=="asc") && moment(a).isBefore(moment(b)) || (order!="asc" && moment(b).isBefore(moment(a)))
        return (isBefore ? -1 : 1)
      },
    },

    {
      dataField: 'title',
      text: t('titolo'),
      sort: true
    },

    {
      dataField: 'lab',
      text: t('Laboratorio'),
      sort: true,
    },
    {
      dataField: 'type',
      text: t('Esperienza'),
      formatter: deviceTitleFormatter,
      sort: true,
    },
    {
      dataField: 'description',
      text: t('Descrizione'),
      formatter: deviceDescriptionFormatter,
      sort: true,
    },
    {
      dataField: 'note',
      text: t('Note'),
      headerAlign: "center",
      sort: false,
      hidden: false
    },
    {
      dataField: 'actions',
      text: t('Azioni'),
      formatter: actionsFormatter,
      headerStyle: () => {
        return { width: '8%' };
      },
      headerAlign: 'center',
      hidden: !next
    },
    {
      dataField: 'homeworkUrl',
      text: t('Report'),
      formatter: homeworkFormatter,
      headerStyle: () => {
        return { width: '8%' };
      },
      headerAlign: 'center',
      hidden: next,
      sort: true
    }
  ];

  const paginationOptions = {
    sizePerPageList: [{
      text: '3th', value: 3
    }
    ], pageStartIndex: 1
  }


  const nextAppointments = appointments.filter((app: any, i: number) => {
    console.log("Cerco esperimento del catalogo con id:", app);

    return experiments != null && experiments[app["title"]] != null &&
      isAppointmentBookedByUser(app,userAttributes) &&
      // filtro nel caso siano stati omessi degli esperimenti dal catalogo corrente
      // non si capisce a che cosa servi(va)---?
      //(props?.userId ? app.attendee == props?.userId : app.attendee == userAttributes.sub) &&
      moment(app["stopDate"]).isAfter(moment.now())
  }).map((app: any, i: number) => {
    return {
      "uid": `${app["id"]}_${app["type"]}_${i}}`,
      "id": app["id"],
      "startDate": `${app["startDate"]}`,
      "stopDate": `${app["stopDate"]}`,
      "title": (experiments[app["title"]] as any)["titolo"],
      "experiment_id": app["title"],
      "organization": app["organization"],
      "lab": app["lab"],
      "note" : app["note"],
      "qrcode_questions_url": (experiments[app["title"]] as any)["qrcode_questions_url"],
      "joinUrl": app["joinUrl"],
      "type": app["type"]
    }
  });

  const lastAppointments = appointments.filter((app: any, i: number) => {
      // filtro nel caso siano state omessi degli esperimenti dal catalogo corrente
    return experiments != null && experiments[app["title"]] != null &&
      isAppointmentBookedByUser(app,userAttributes) &&
      (props?.userId ? app.attendee == props?.userId : app.attendee == userAttributes.sub) &&
      !moment(app["stopDate"]).isAfter(moment.now())
  }).filter((app: any, i: number) => { return (experiments[app["title"]] as any) != null }).map((app: any, i: number) => {
    //console.log("Last app id:", app["id"]);
    return {
      "uid": `${app["id"]}_${app["type"]}_${i}}`,
      "id": app["id"], "startDate": `${app["startDate"]}`, "stopDate": `${app["stopDate"]}`,
      "experiment_id": app["title"],
      "organization": app["organization"],
      "title": (experiments[app["title"]] as any)["titolo"],
      "lab": app["lab"],
      "note" : app["note"],
      "joinUrl": app["joinUrl"],
      "type": app["type"],
      "homeworkUrl" : app["homeworkUrl"]
    }
  });

  //console.log("dashboard nextAppointments:", nextAppointments);
  //console.log("dashboard lastappointments:", lastAppointments);
  return (
    <> 

      <Modal isOpen={appointmentToUnbook!=null || isUnbookingPending}>
        <ModalHeader>{t("Cancellazione della prenotazione")}</ModalHeader>
        <ModalBody>{t("Confermare la cancellazione dell'appuntamento?")}</ModalBody>
        <ModalFooter>
          <ActivityButton color="primary" name="unbookAppointment" onClick={(ev:any) =>unbookAppointment(appointmentToUnbook)}>{t("Si")}</ActivityButton>{' '}
          <Button color="secondary" onClick={closeUnbookAppointmentModal}>{t("No")}</Button>
        </ModalFooter>
  </Modal>

      <Card className="m-4" style={{
        marginTop: "-10px",
        borderColor: "#007bff"
      }}>
        <CardHeader data-tip={t("Area tematica")} style={{
          backgroundColor: "#007bff",
          borderColor: "#007bff",
          paddingBottom: 0,
          color: 'white'

        }}>
          <CardTitle tag="h5">{t("Sessioni pratiche in programma")}</CardTitle>
        </CardHeader>
        <CardBody>
          {
            nextAppointments.length > 0 ?
              (
                <BootstrapTable bootstrap4 keyField="uid" data={
                  nextAppointments
                }
                  columns={columns(true)} pagination={paginationFactory(paginationOptions)} />)
              :
              <Alert color="info" className="text-center">
                {t("alertNoBookedInteractiveSessions")}
              </Alert>
          }
        </CardBody>
      </Card>

      <Card className="m-4" style={{
        borderColor: "#007bff"
      }}>
        <CardHeader data-tip={t("Area tematica")} style={{
          backgroundColor: "#007bff",
          borderColor: "#007bff",
          paddingBottom: 0,
          color: 'white'

        }}>
          <CardTitle tag="h5">{t("Ultime sessioni pratiche")}</CardTitle>
        </CardHeader>
        <CardBody>
          {
            lastAppointments.length > 0 ?
              <BootstrapTable bootstrap4 keyField="uid" data={
                lastAppointments
              }
                columns={columns(false)} 
                defaultSorted={[{dataField:"startDate", order:"desc"}]}
                pagination={paginationFactory(paginationOptions)} />
              :
              <Alert color="info" className="text-center">
                {t("alertNoPastInteractiveSession")}
              </Alert>
          }
        </CardBody>
        <CardFooter>
          <Footer />
        </CardFooter>
      </Card>
  
    </>
  )
}