import { useState } from 'react';
import 'react-big-calendar/lib/sass/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import { Header, Footer } from '../header'

import { Content } from '../components/Content';

import { useSelector } from 'react-redux';

import { selectors as AuthSelectors } from '../store/slices/auth'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { useTranslation } from 'react-i18next';

import {
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';

import { SyncSessionsDashboard } from '../components/Dashboards/SyncSessionsDashboard';
import { InteractiveSessionsDashboard } from '../components/Dashboards/InteractiveSessionsDashboard';
import TeacherClassroom from '../components/TeacherClassroom';
import ClassroomInteractiveSessionsDashboard from '../components/ClassroomInteractiveSessionsDashboard';
import { SYNCHRONOUS_SESSION_TYPE, INTERACTIVE_SESSION_TYPE } from '../store/sagas/remotesessions';
import { ContestSchoolsAnalyzer } from '../components/Contest2024/ContestSchoolsAnalyzer';

export const DashboardPage = () => {

  const isTeacher = useSelector(AuthSelectors.isTeacher);
  const isStudent = useSelector(AuthSelectors.isStudent);
  const { t } = useTranslation('frontend', { useSuspense: false });
  const [activeTab, setActiveTab] = useState(isStudent ? "1" : "0");
  const userProfile = useSelector(ProfileSelectors.getProfile)
  const currentRegistrationProfile = useSelector(AuthSelectors.getRegistrationProfile)

  return (
    <>
      <Header className="mb-0 text-white" section="Dashboard" showMenu={false} />
      <Content active="dashboard" section="dashboard">

        <Nav style={{ marginTop: "15px" }} tabs>
          {!isStudent &&
            <NavItem>
              <NavLink style={activeTab === '0' ?
                { cursor: "arrow", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }}

                onClick={() => { setActiveTab('0'); }}
              >
                {t("Sessioni sincrone")}
              </NavLink>
            </NavItem>
          }
          {/* SESSIONI PRATICHE NASCOSTE FINO A CHE NON SI DEFINISCONO LE LIBERATORIE
          <NavItem>
            <NavLink
              style={activeTab === '1' ?
                { cursor: "arrow", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }}

              onClick={() => { setActiveTab("1"); }}
            >
              {t("Sessioni pratiche")}
            </NavLink>
          </NavItem>
            */}
          {isTeacher && currentRegistrationProfile?.contest2024 &&
            <NavItem>
              <NavLink
                style={activeTab === '2' ?
                  { cursor: "arrow", fontWeight: "bold", background: "#EEEEEE" } : { cursor: "pointer", fontWeight: "normal" }}

                onClick={() => { setActiveTab("2"); }}
              >
                {t("I miei studenti")}
              </NavLink>
            </NavItem>
          }
          {isTeacher && currentRegistrationProfile?.contest2024 &&
          <NavItem>
            <NavLink  
              style={activeTab === '3' ? 
              {cursor:"arrow" , fontWeight:"bold" , background:"#EEEEEE"} : {cursor:"pointer", fontWeight:"normal"}}
              onClick={() => { setActiveTab("3"); }}
            >
              {t("Contest 2024")}
            </NavLink>
          </NavItem>}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="0">
            <SyncSessionsDashboard />
          </TabPane>
          <TabPane tabId="1">
            {
              isStudent ?  <InteractiveSessionsDashboard/> :
              <ClassroomInteractiveSessionsDashboard/>
            }
           
          </TabPane>
          <TabPane tabId="2">
            <TeacherClassroom />
          </TabPane>
          <TabPane tabId="3">
            {
              userProfile?.sub && (
                  <ContestSchoolsAnalyzer teacherId={userProfile?.sub} />
              )
            }
            
          </TabPane>
        </TabContent>
      </Content>
    </>
  )
}