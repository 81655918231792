import { IT, GB } from 'country-flag-icons/react/3x2'
import i18n from '../i18n';
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {actions as CatalogActions} from '../store/slices/catalog'
import moment from 'moment';

const LanguageSelector = (props: any) => {

  const dispatch = useDispatch();

  useEffect(() => {
    const language = localStorage.getItem("riale_language")
    if (language!=null)
    {
      //console.log(`Trovato language:${language} i18n:${i18n.language}`)
      if (language!=i18n.language)
      {
        i18n.changeLanguage(language as any);
        moment.locale(language);
      }
    }
  }, [])


  
  useEffect(() => {
    // This will be now called when the locale is changed
    //console.log("RELOAD CATALOG -> RIALE-EU LANGUAGE SET TO:",i18n.language)
    //n.b: il passaggio della lingua corrente non è necessario in quanto
    // l'api di catalog leggera direttamente il valore corrente di i18n.language;
    dispatch(CatalogActions.willLoadCatalog());

    }, [i18n.language]);

    if (props.hidden) return null; 
    else
    return <div style={{ "display": "flex", "justifyContent": "center" , "margin":"10px" }}>
      <div style={{"display": "flex", "justifyContent": "flex-start", "alignItems": "center" }}>
        <GB style={{"cursor" : "pointer", "marginRight": "10px" }} width="30px" title="English" 
        onClick={() => { 
          localStorage.setItem("riale_language" ,'en-US');
          i18n.changeLanguage('en-US'); 
          moment.locale('en-US');
          }} />
        <IT style={{"cursor" : "pointer", "marginRight": "10px" }} width="30px" title="Italiano" 
        onClick={() => { 
          localStorage.setItem("riale_language" ,'it-IT');
          i18n.changeLanguage('it-IT'); 
          moment.locale('it-IT');
          }}/>
      </div>
  
    </div>
  
  }

  export default LanguageSelector;