import { API } from 'aws-amplify';
import { loader } from 'graphql.macro';

// rabella con le versioni di privacy sottoscritte dagli utenti: riale-calendar-beta-privacy-acceptance
//link per accesso al db 
// https://eu-west-1.console.aws.amazon.com/dynamodbv2/home?region=eu-west-1#table?name=riale-calendar-beta-user-profile&initialTableGroup=%23all
// Link API rl_cal_beta-cognito
//https://eu-west-1.console.aws.amazon.com/appsync/home?region=eu-west-1#/225m6fbcffao3np6avgy73vfai/v1/home


//interface IPrivacyVersion {url:string, version:string};

export const getCurrentPrivacyVersion = async () => {
  const query = loader('../graphql/getCurrentPrivacyVersion.gql');
  //console.log('getCurrentPrivacyVersion query: ', query)
  try {
    const result: any = await API.graphql({ query: query });
    //console.log("Privacy version", result);
    return result
  } catch (error) {
    //console.log("Error in getCurrentPrivacyVersion query:", error);
    throw error
  }
}


export const getPrivacyAcceptance = async () => {
  const query = loader('../graphql/getPrivacyAcceptance.gql');
  //console.log('getPrivacyAcceptance query: ', query)
  try {
    const result: any = await API.graphql({ query: query });
    //console.log("getPrivacyAcceptance result", result);
    return result
  } catch (error) {
    //console.log("Error in getPrivacyAcceptance query:", error);
    throw error
  }
}


export const getNewsletterAcceptance = async () => {
  const query = loader('../graphql/getNewsletterAcceptance.gql');
  //console.log('getNewsletterAcceptance query: ', query)
  try {
    const result: any = await API.graphql({ query: query });
    //console.log("getNewsletterAcceptance result", result);
    return result
  } catch (error) {
    //console.log("Error in getPrivacyAcceptance query:", error);
    throw error
  }
}


export const setNewsletterAcceptance = async (
  accepted: boolean,
) => {
  const query = loader("../graphql/setNewsletterAcceptance.gql");
  //console.log("setNewsletterAcceptance query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {
        accepted
      },
    });
    return result;
  } catch (error) {
    //console.log("Error in setNewsletterAcceptance Query", error);
    throw error;
  }
};



export const getUserProfile = async () => {
  const query = loader('../graphql/getUserProfile.gql');
  //console.log('getUserProfile query: ', query)
  try {
    const result: any = await API.graphql({ query: query });
    return result
  } catch (error) {
    //console.log("Error in getUserProfile query:", error);
    throw error
  }
}

export const setUserProfile = async (
  user: any,
  school: any,
  schoolType: any,
  schoolLevel: any,
  schoolMatter: any,
  privacy: any,
  ideaSchool: any,
  type: any,
  country: any,
  teacherCode: any,
  contest2024: any,
  schoolGrade: any,
  schoolSection: any,
  allowedStudents: any
) => {
  const query = loader("../graphql/setUserProfile.gql");
  //console.log("setUserProfile query: ", query);
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {
        user,
        school,
        schoolType,
        schoolLevel,
        schoolMatter,
        privacy,
        ideaSchool,
        country,
        type,
        teacherCode,
        contest2024,
        schoolGrade,
        schoolSection,
        allowedStudents
      },
    });
    return result;
  } catch (error) {
    //console.log("Error in setUserProfile Query", error);
    throw error;
  }
};



export const acceptPrivacy = async (
) => {
  const query = loader("../graphql/acceptPrivacy.gql");
  //console.log("acceptPrivacy query: ", query);
  try {
    const result: any = await API.graphql({
      query: query
    });
    return result;
  } catch (error) {
    //console.log("Error in acceptPrivacy Query", error);
    throw error;
  }
};