import moment from 'moment';
import ReactExport from "react-data-export";
import { FaFileExcel } from "react-icons/fa";
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from "react-tooltip";
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors as UserSelector } from '../../store/slices/users'

export const Contest2024ExcelReport = ({ data, startDate, stopDate }) => {
    const { t } = useTranslation('frontend', { useSuspense: false });
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const users = useSelector(UserSelector.getUsers2)
    const DATE_TIME_FORMAT = "DD/MM/YYYY (HH:mm)";

    // const newDateStart = moment(newDate, DATE_TIME_FORMAT);
    useEffect(() => {
        console.log("CER: data:", data)
    }, [])

    const getSheetHeader = () => {
        const fromMsg = (startDate == null) ? "" :
            `dal ${moment(startDate).format(DATE_TIME_FORMAT)}`
        const toMsg = (stopDate == null) ? "" :
            `fino al ${moment(stopDate).format(DATE_TIME_FORMAT)}`
        const title = `Contest 2024 – Analisi risultati di tracciamento ${fromMsg} ${toMsg}`
        return [
            {
                value: title,
                style: { font: { bold: true, sz: 14 } }
            },
        ]
    }
    const getFileName = () => {

        const now_date = moment(moment.now()).format("DD_MM_YY")
        const now_time = moment(moment.now()).format("HH:mm")
        return `report_Contest2024_${now_date}_h_${now_time}`;
    }
    const generateOverviewSheet = (data) => {


        // Funzione per preparare il contenuto
        const generateData = (traceData) => {


            const headers = [
                [  //Scuola	Classe	Docente	Email	Punteggio	Studenti iscritti	Studenti partecipanti	# timeline

                    { value: "Scuola", style: { font: { bold: true } } },
                    { value: "Classe", style: { font: { bold: true } } },
                    { value: "Docente", style: { font: { bold: true } } },
                    { value: "Email", style: { font: { bold: true } } },
                    { value: "Punteggio", style: { font: { bold: true } } },
                    { value: "Studenti iscritti", style: { font: { bold: true } } },
                    { value: "Studenti partecipanti", style: { font: { bold: true } } },
                    { value: "Timeline analizzate", style: { font: { bold: true } } },
                ],
            ];
            const sortedDataByScore = [...traceData].sort((a, b) => { return b.score - a.score })
            const records = sortedDataByScore.map((item) => [
                { value: item.school },
                { value: item.classroom },
                { value: `${item.full_name}` },
                { value: item.email },
                { value: item.score,  style: {
                    numFmt: "0.00", // Imposta il formato  con 2 cifre decimali
                    alignment: { horizontal: "center", vertical: "center" }, 
                } },
                { value: item.tot_rolled_partecipants, 
                    style: {alignment: { horizontal: "center", vertical: "center" }} },
                { value: item.tot_partecipants,
                    style: {alignment: { horizontal: "center", vertical: "center" }}
                 },
                { value: item.tot_timelines,
                    style: {alignment: { horizontal: "center", vertical: "center" }}
                 }
            ]);

            return [getSheetHeader(), [], ...headers, ...records];
        };

        const dataSet = [
            {
                columns: [],
                data: generateData(data),
            },
        ];
        return { "name": "Riepilogo", "data": dataSet };
    }

    const getTimelineDataSet = (entry, timeline) => {
        return {   // dataset
            ySteps: 1,
            columns: [
                { "title": "Timeline" },
                { "title": "Copertura Media Video (%)" },
                { "title": "Copertura Media Allegati (%)" },
            ],
            data: [[
                { "value": timeline.title },
                {
                    "value": entry.tracesOverview[timeline.id]["score"]["coperturaMediaVideoPerc"],
                    style: {
                        numFmt: "0.00", // Imposta il formato  con 2 cifre decimali
                        alignment: { horizontal: "center", vertical: "center" }, 
                    },
                },
                { "value": entry.tracesOverview[timeline.id]["score"]["coperturaMediaAllegatiPerc"],
                style: {
                    numFmt: "0.00", // Imposta il formato  con 2 cifre decimali
                    alignment: { horizontal: "center", vertical: "center" }, 
                }, }
            ]]
        }
    }

    const getTimelinesDatasets = (entry) => {
        const datasets = []
        entry.timelines.forEach((timeline) => {
            const timelineDataset = getTimelineDataSet(entry, timeline);
            datasets.push(timelineDataset);
            const timelineStudentsDataset = getTimelineStudentsDataset(entry, timeline)
            datasets.push(timelineStudentsDataset);
        })
        return datasets;
    }

    const getTimelineStudentsDataset = (entry, timeline) => {
        const timelineStudents = Object.keys(entry.tracesOverview[timeline.id]?.students) || [];
        const studentsData = []
        for (let i = 0; i < timelineStudents.length; i++) {
            studentsData.push([
                { "value": `${users[timelineStudents[i]]["given_name"]} ${users[timelineStudents[i]]["family_name"]}` },
                { "value": `${users[timelineStudents[i]]["email"]}` },
                { "value": entry.tracesOverview[timeline.id]["students"][timelineStudents[i]]["coperturaVideo"]["coperturaVideoPercentuale"] ,
                    style: {
                        numFmt: "0.00", // Imposta il formato  con 2 cifre decimali
                        alignment: { horizontal: "center", vertical: "center" }, 
                    }
                },
                { "value": entry.tracesOverview[timeline.id]["students"][timelineStudents[i]]["percentualeAllegatiAperti"],
                    style: {
                        numFmt: "0.00", // Imposta il formato  con 2 cifre decimali,
                        alignment: { horizontal: "center", vertical: "center" }, 
                    }
                 }
            ]);
        }
        return {   // dataset
            xSteps: 1,
            columns: [
                { "title": "Studente" },
                { "title": "Email" },
                { "title": "Copertura Video (%)" },
                { "title": "Copertura Allegati (%)" },
            ],
            data: studentsData
        }
    }

    const generateSchoolSheet = (schoolData) => {
        console.log("CER:::", schoolData)
        const multiDataSet = [
            {
                columns: [{ "title": "" }],
                data: [getSheetHeader()]
            },
            {
                ySteps: 1,
                columns: [
                    { "title": "Scuola" },
                    { "title": "Classe" },
                    { "title": "Docente" },
                    { "title": "Email" },
                    { "title": "Punteggio" },
                    { "title": "Studenti iscritti" },
                    { "title": "Studenti partecipanti" },
                    { "title": "Timeline analizzate" }
                ],
                data: [[
                    { value: schoolData.school },
                    { value: schoolData.classroom },
                    { value: `${schoolData.full_name}` },
                    { value: schoolData.email },
                    { value: schoolData.score,  style: {
                        numFmt: "0.00", // Imposta il formato  con 2 cifre decimali
                        alignment: { horizontal: "center", vertical: "center" }, 
                    } },
                    { value: schoolData.tot_rolled_partecipants, style: {alignment: { horizontal: "center", vertical: "center" }} },
                    { value: schoolData.tot_partecipants, style: {alignment: { horizontal: "center", vertical: "center" }} },
                    { value: schoolData.tot_timelines, style: {alignment: { horizontal: "center", vertical: "center" }} }
                ]]
            },
            ...getTimelinesDatasets(schoolData),
            ,
            /*
            {   // dataset
                ySteps: 1,
                columns: [
                { "title": "Timeline" }, 
                { "title": "Studente" },
                { "title": "Email" },
                { "title": "Copertura Media Video (%)" },
                { "title": "Copertura Media Allegati (%)" },
                ],
                //data: [[{"value" : schoolData.school}, {"value" : schoolData.classroom}]]
                data: generateTimelinesDataset(schoolData)
            }
                */
        ]

        return { "name": `${schoolData.school}_${schoolData.classroom}`, "data": multiDataSet };
    }

    const generateSchoolSheets = (data) => {
        return data.map((entry) => {

            const res = generateSchoolSheet(entry)
            console.log("CER: school data:", res)
            return res
        })
    }

    const sheets = [generateOverviewSheet(data), ...generateSchoolSheets(data)]; //, generateSchoolSheets(data)[0]
    console.log("CER_SHEETS:", sheets)
    return (users &&
        <ExcelFile filename={getFileName()}
            element={
                <IconButton
                    style={{ float: 'right', marginTop: '-10px', marginRight: '-10px' }}
                >
                    <IconContext.Provider
                        value={{ color: `white`, size: "1em" }}>
                        <FaFileExcel data-place="top"
                            data-for="excelExportTooltip" data-tip={t("Esporta in formato Excel gli utenti che aderiscono alla newsletter")}
                        />
                    </IconContext.Provider>
                </IconButton>
            }>
            {sheets.map((sheet, index) => (
                <ExcelSheet dataSet={sheet.data} name={sheet.name} key={index} />
            ))}
        </ExcelFile>
    );
};


const testData = [
    {
        "id": "0182b756-c0fc-4ea5-8797-bc3d609f8a68_5b",
        "school": "Liceo Michelangelo",
        "teacherId": "0182b756-c0fc-4ea5-8797-bc3d609f8a68",
        "classroom": "5b",
        "tot_partecipants": 0,
        "tot_rolled_partecipants": 0,
        "tot_timelines": 2,
        "timelines": [
            {
                "id": "c38a72a8-00b5-4278-a871-9e1e948f934c",
                "catalogExperiment": "",
                "title": "Timeline di test MODIFICATA con versione mobile"
            }
        ],
        "given_name": "Stefano",
        "family_name": "Monni (teacher2)",
        "full_name": "Monni (teacher2) Stefano",
        "email": "stefano.monni+teacher2@gmail.com",
        "tracesOverview": {
            "c38a72a8-00b5-4278-a871-9e1e948f934c": {
                "score": {
                    "coperturaMediaVideoPerc": 0,
                    "coperturaMediaAllegatiPerc": 0
                },
                "students": []
            },
            "undefined": {
                "score": {
                    "coperturaMediaVideoPerc": 0,
                    "coperturaMediaAllegatiPerc": 0
                },
                "students": []
            }
        },
        "score": 0
    },
    {
        "id": "0dd41726-b4f4-49c4-805c-2672324534a2_4h",
        "school": "(AGIC812006) SCUOLA PRIMO GRADO IC - A. MANZONI - CIANCIANA",
        "teacherId": "0dd41726-b4f4-49c4-805c-2672324534a2",
        "classroom": "4h",
        "tot_partecipants": 0,
        "tot_rolled_partecipants": 0,
        "tot_timelines": 1,
        "timelines": [
            {
                "id": "c38a72a8-00b5-4278-a871-9e1e948f934c",
                "catalogExperiment": "",
                "title": "Timeline di test MODIFICATA con versione mobile"
            }
        ],
        "given_name": "Stefano",
        "family_name": "Monni (teacher 11)",
        "full_name": "Monni (teacher 11) Stefano",
        "email": "stefano.monni+teacher11@gmail.com",
        "tracesOverview": {
            "c38a72a8-00b5-4278-a871-9e1e948f934c": {
                "score": {
                    "coperturaMediaVideoPerc": 0,
                    "coperturaMediaAllegatiPerc": 0
                },
                "students": {"cd1456b4-1168-432c-8db3-879ab20d2cc5": {
                    "id": "cd1456b4-1168-432c-8db3-879ab20d2cc5",
                    "durata": 202.35,
                    "numeroVideo": 1,
                    "numeroAllegati": 1,
                    "numeroAllegatiAperti": 1,
                    "percentualeAllegatiAperti": 100,
                    "clickAllegati": 10,
                    "coperturaVideo": {
                        "coperturaVideoPercentuale": 90.065033358043,
                        "secondiVisti": 182.24659499999999,
                        "info": "182.25 secs. (90.07%)"
                    },
                    "videoIntervals": [
                        {
                            "percentage": 78.90655102545095,
                            "color": "success"
                        },
                        {
                            "percentage": 0.00002026192241668979,
                            "color": "danger"
                        },
                        {
                            "percentage": 11.158482332592037,
                            "color": "success"
                        },
                        {
                            "percentage": 9.934946380034592,
                            "color": "danger"
                        }
                    ]
                }}
            }
        },
        "score": 0
    },
    {
        "id": "3c952859-129f-4632-a078-d5b109ef2023_5fsa",
        "school": "(AGIC805003) SCUOLA INFANZIA IC - G. GALILEI - JOPPOLO GIANCAXIO",
        "teacherId": "3c952859-129f-4632-a078-d5b109ef2023",
        "classroom": "5fsa",
        "tot_partecipants": 1,
        "tot_rolled_partecipants": 1,
        "tot_timelines": 1,
        "timelines": [
            {
                "id": "c38a72a8-00b5-4278-a871-9e1e948f934c",
                "catalogExperiment": "",
                "title": "Timeline di test MODIFICATA con versione mobile"
            }
        ],
        "given_name": "Stefano",
        "family_name": "Monni (T4)",
        "full_name": "Monni (T4) Stefano",
        "email": "stefano.monni+teacher4@gmail.com",
        "tracesOverview": {
            "c38a72a8-00b5-4278-a871-9e1e948f934c": {
                "score": {
                    "coperturaMediaVideoPerc": 10.95945800565088,
                    "coperturaMediaAllegatiPerc": 20
                },
                "students": []
            }
        },
        "score": 13.21959350423816
    },
    {
        "id": "81685b64-4312-4333-b9fc-bac8fc6e24b7_4p",
        "school": "Azuni (simulazione)",
        "teacherId": "81685b64-4312-4333-b9fc-bac8fc6e24b7",
        "classroom": "4p",
        "tot_partecipants": 0,
        "tot_rolled_partecipants": 0,
        "tot_timelines": 1,
        "timelines": [
            {
                "id": "c38a72a8-00b5-4278-a871-9e1e948f934c",
                "catalogExperiment": "",
                "title": "Timeline di test MODIFICATA con versione mobile"
            }
        ],
        "given_name": "Stefano ",
        "family_name": "Monni (T3)",
        "full_name": "Monni (T3) Stefano ",
        "email": "stefano.monni+teacher3@gmail.com",
        "tracesOverview": {
            "c38a72a8-00b5-4278-a871-9e1e948f934c": {
                "score": {
                    "coperturaMediaVideoPerc": 0,
                    "coperturaMediaAllegatiPerc": 0
                },
                "students": []
            }
        },
        "score": 0
    },
    {
        "id": "d3deab88-60dd-4e8d-8cec-1f13e2dba31b_2fsa",
        "school": "(AGIC805003) SCUOLA INFANZIA IC - G. GALILEI - JOPPOLO GIANCAXIO",
        "teacherId": "d3deab88-60dd-4e8d-8cec-1f13e2dba31b",
        "classroom": "2fsa",
        "tot_partecipants": 0,
        "tot_rolled_partecipants": 0,
        "tot_timelines": 1,
        "timelines": [
            {
                "id": "c38a72a8-00b5-4278-a871-9e1e948f934c",
                "catalogExperiment": "",
                "title": "Timeline di test MODIFICATA con versione mobile"
            }
        ],
        "given_name": "Stefano",
        "family_name": "Monni",
        "full_name": "Monni Stefano",
        "email": "stefano.monni+teacher1@gmail.com",
        "tracesOverview": {
            "c38a72a8-00b5-4278-a871-9e1e948f934c": {
                "score": {
                    "coperturaMediaVideoPerc": 0,
                    "coperturaMediaAllegatiPerc": 0
                },
                "students": []
            }
        },
        "score": 0
    },
    {
        "id": "d3deab88-60dd-4e8d-8cec-1f13e2dba31b_4c",
        "school": "(AGIC805003) SCUOLA INFANZIA IC - G. GALILEI - JOPPOLO GIANCAXIO",
        "teacherId": "d3deab88-60dd-4e8d-8cec-1f13e2dba31b",
        "classroom": "4c",
        "tot_partecipants": 1,
        "tot_rolled_partecipants": 2,
        "tot_timelines": 1,
        "timelines": [
            {
                "id": "cd1456b4-1168-432c-8db3-879ab20d2cc5",
                "catalogExperiment": "",
                "title": "Lezione del docente per gli studenti"
            }
        ],
        "given_name": "Stefano",
        "family_name": "Monni",
        "full_name": "Monni Stefano",
        "email": "stefano.monni+teacher1@gmail.com",
        "tracesOverview": {
            "cd1456b4-1168-432c-8db3-879ab20d2cc5": {
                "score": {
                    "coperturaMediaVideoPerc": 1.6356137879911046,
                    "coperturaMediaAllegatiPerc": 0
                },
                "students": []
            }
        },
        "score": 1.2267103409933284
    }
]