import { toUpper } from 'lodash';
import ReactTooltip from "react-tooltip";
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import {
  Card, CardText, CardBody, CardLink,
  CardHeader, CardFooter, CardTitle, CardSubtitle, Badge,
} from 'reactstrap';
import "./Experiment.css"
import Flag from 'react-world-flags'
import RialeAccordion from './RialeAccordion'
import { useSelector } from 'react-redux'
import { selectors as AuthSelectors } from '../store/slices/auth'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { useEffect, useState } from 'react';
import { selectors as ExperimentsSelector, actions as ExperimentsActions } from '../store/slices/experiments'

export const Experiment = (props: any) => {

  const { content } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  const isLogged = useSelector(AuthSelectors.isLogged);
  const publicLiteTimelines = useSelector(ExperimentsSelector.getPublicLiteTimelines)
  const isDirector = useSelector(ProfileSelectors.isDirector);
  const [isTimelineMasterLite, setTimelineMasterLite] = useState(null) as any;
  const [publicTimelineLoadrequestSent, setPublicTimelineLoadrequestSent] = useState(false)

  useEffect(() => {
    if (publicLiteTimelines != null && isTimelineMasterLite==null && content?.timeline_pubbliche?.length > 0)
    {
      setTimelineMasterLite(publicLiteTimelines[(content.timeline_pubbliche as any)[0].id]?.isLite == true)
      //console.log("publicLiteTimelines:", publicLiteTimelines)
    }
  },
    [publicLiteTimelines, content])


  useEffect(() => {
    //dispatch(ExperimentsActions.setCurrentPublicExperiment(null));
    if (content.timeline_pubbliche?.length > 0) {
      const id = content.timeline_pubbliche[0].id
      //console.log(`ESPERIMENTO (TL) Timeline ${content.titolo} ID:`, id)
      //if (id=="0371dd2d-c0dc-4fee-b9b4-e52f4ae70a9c")
      if (id != null && id != "" && !publicTimelineLoadrequestSent)
        dispatch(ExperimentsActions.willGetPublicExperiment(id));
      setPublicTimelineLoadrequestSent(true);
    }
    //console.log("Content:", content)
    return () => {
      dispatch(ExperimentsActions.setCurrentPublicExperiment(null));
    }
  }, [dispatch, publicTimelineLoadrequestSent])

  // console.log("esperimento:", content);



  const renderGradi = () => {
    return content.gradi.map((grado: string, index: number) => {
      return (
        <Badge key={`${grado}_${index}`} href="#"
          onClick={() => { dispatch(push(`/?search=${grado}`)); }}
          style={{ margin: '2px' }} color="primary"> {grado}</Badge>)
    })
  };

  const renderAvalableLanguages = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Link to={{ pathname: `/experiment/${content.id}`, hash: `${isLogged ? "timelines" : "calendar"}` }} >
            {renderTimelineFlags()}
          </Link>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Link to={{ pathname: `/experiment/${content.id}`, hash: "interactive_sessions" }} >
            {renderInteractiveSessions()}
          </Link>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Link to={{ pathname: `/experiment/${content.id}`, hash: "calendar" }} >
            {renderLiveSessionsFlags()}
          </Link>
        </div>


      </div>)
  }

  const renderTimelineFlags = () => {

    const timelines = content.timeline_pubbliche;
    if (timelines == null || timelines.length < 1) return null;
    const languages = new Set()
    for (let i = 0; i < timelines; i++) {
      if (timelines[i]["languages"] != null)
        languages.add(timelines[i]["languages"])
      else languages.add("IT");
    }
    if (languages.size < 1) { languages.add("IT"); }
    //console.log("LANGUAGES:::", languages)

    return (
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
        {isDirector && (
            <Badge pill style={{ paddingTop:"5px" , marginRight:"5px"}} 
            color={`${isTimelineMasterLite==true ? "success" : "danger"}`}>L</Badge> 
        )}
       <b>{"Timeline"}</b>
        {Array.from(languages).map((language: any, index: any) => {
          return <Flag key={index} code={language} height="14" style={{ "marginTop": "4px", "marginLeft": "5px", "marginRight": "5px" }} />
        })}
      </div>
    )
  }

  const renderLiveSessionsFlags = () => {
    //Codici dei paesi: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
    if (!content.live) return null;
    const countries = content.languages || ["IT"];
    return (
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
        <b> {t(`Sessioni sincrone`)}</b>
        {countries.map((language: string) => {
          return <>
            <Flag code={language} height="14" style={{ "marginTop": "4px", "marginLeft": "5px", "marginRight": "5px" }} />
          </>
        })}
      </div>
    )
  }

  const renderInteractiveSessions = () => {
    //Codici dei paesi: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
    if (!content.interactive_devices || !content.interactive_devices[0]) return null;
    return (
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
        <b> {t(`Sessioni pratiche`)}</b>
      </div>
    )
  }

  const renderTags = () => {
    return content.tags.length > 0 && (
      <RialeAccordion title={t("Parole chiave")}>
        {content.tags.map((tag: string, index: number) => {
          return (
            <Badge key={`${tag}_${index}`} href="#" onClick={() => { dispatch(push(`/?search=${tag}`)); }} style={{ margin: '2px' }} color="success"> {tag}{'    '}</Badge>)
        })
        }
      </RialeAccordion>
    )
  };

  const renderDescrizione = () => {
    ////console.log("Contenuto esperimento:", content)
    return (
      <div style={{
        display: "flex", flexDirection: "column", justifyContent: "flex-start",
        alignItems: "flex-end"
      }}>
        <div
          className="truncated-html"
          dangerouslySetInnerHTML={{ __html: content.disabled || content.descrizione }}
        />
        {
          !content.disabled && (
            <span style={{ marginTop: "5px" }}>
              <a href="#" onClick={() => { dispatch(push(`/experiment/${content.id}`)); }} >
                {`${t("read_all")}`}
              </a>
            </span>
          )
        }

      </div>)
  }

  return (
    <Card className="mb-3">
      <CardBody>

        <CardTitle data-tip={t("Esperimento")} tag="h6" className="clearfix">
          <div className="float-left" style={{ fontWeight: 'bold', color: "#007bff" }}
            data-type="info" data-html={true}
            data-tip=
            {content.live ? (`<br><ul><li>${t("Visualizza i dettagli")}</li><li>${t("Accedi alla Timeline")}</li><li>${t("Prenota la sessione live")}</li></ul>`)
              : (`<br><ul><li>${t("Visualizza i dettagli")}</li><li>${t("Accedi alla Timeline")}</li></ul>`)}
          >
            <a href="#" onClick={() => { dispatch(push(`/experiment/${content.id}`)); }} >
              {content.titolo} <small>({content.durata} MIN.)</small>
            </a>
          </div>

          {content.live && false &&
            <div style={{ marginLeft: "10px" }} className="float-left" > <Badge color="danger">Live</Badge></div>
          }
        </CardTitle>
        <CardSubtitle tag="h6" className="mb-2 text-muted">
          <small>{toUpper(content.laboratorio)}</small>

        </CardSubtitle>

        <div style={{ marginTop: "10px", marginBottom: "12px" }}>{renderDescrizione()}</div>

        <div style={{ marginTop: "10px", marginBottom: "12px" }}>{
          <>
            <Badge color={`${(isTimelineMasterLite==true && content.live)? "success" : "secondary"}`}>Contest 2024</Badge>
            {renderGradi()}
          </>
        }</div>
        <div style={{ marginTop: "10px", marginBottom: "12px" }}>{renderAvalableLanguages()}</div>
        {renderTags()}
      </CardBody>
      {/* <CardFooter>
        Durata: {content.durata} minuti
      </CardFooter> */}
      <ReactTooltip />
    </Card >)

}