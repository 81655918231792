import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import { actions as UsersActions, selectors as UsersSelector } from '../slices/users'
import { getUser } from '../../api/sessions'
import { getUserProfileAsDirector } from '../../api/directors'
import { getRegisteredUsers, getStudentsByTeacherCode } from '../../api/registered_users'


export function* sagas() {
    yield takeEvery(UsersActions.willGetUser.type, willGetUser);
    yield takeEvery(UsersActions.willGetUserProfileAsDirector.type, willGetUserProfileAsDirector);
    yield takeEvery(UsersActions.willGetRegisteredUsers.type, willGetRegisteredUsers);
    yield takeEvery(UsersActions.willGetStudentsByTeacherCode.type, willGetStudentsByTeacherCode);

}

export function* willGetUserProfileAsDirectorForced(action: any):any 
{
    const userID = action.payload;
    try {
        //console.log("calling getUserProfileAsDirector on userID:",userID);
        const result = yield call(getUserProfileAsDirector, userID);
        //console.log("SAGA graphql: getUserProfileAsDirector result:->", result);
        const userProfileData = result["data"]["getUserProfileAsDirector"];
        yield put(UsersActions.didGetUserProfileAsDirector(userProfileData));
        //console.log("SAGA graphql: userProfile Found::->", userProfileData);
        return userProfileData;
    } catch(error){
        console.log("SAGA:getUserProfileAsDirectorForced fails error ", error)
        return null;
    }
}

export function* willGetUserProfileAsDirector(action: any):any {
    //console.log("SAGA: willGetUserProfileAsDirector:", action);
    try {
        const userID = action.payload;

        // Verifico che le informazioni del profilo dell'utente non siano già
        // disponibili nello store.
        const userDataFromStore = yield select(UsersSelector.getUserProfileAsDirector(userID));
        if (userDataFromStore != null) {
            //console.log("DDDD SAGA graphql: willGetUserUserProfile Profilo Utente trovato:", userDataFromStore);
            yield put(UsersActions.didGetUserProfileAsDirector(userDataFromStore));
            return userDataFromStore;
        }
        //console.log("GUP: calling getUserProfileAsDirector on userID:",userID);
        const result = yield call(getUserProfileAsDirector, userID);
        //console.log("SAGA graphql: getUserProfileAsDirector result:->", result);
        const userProfileData = result["data"]["getUserProfileAsDirector"];
        yield put(UsersActions.didGetUserProfileAsDirector(userProfileData));
        //console.log("GUP: SAGA graphql: userProfile Found::->", userProfileData);
        return userProfileData;
    } catch (error) {
        console.log("GUP: SAGA:getUserProfileAsDirector fails error ", error)
        return null;
    }
}

export function* willGetStudentsByTeacherCode(action: any):any {
    try{
        const teacherCode = action.payload;
        //console.log("getStudentsByTeacherCode teacherCode:", teacherCode);
        const myStudents = (yield call(getStudentsByTeacherCode,teacherCode));
        //console.log("MyStudents:",myStudents);
        yield put(UsersActions.didGetMyStudents(myStudents));
        return myStudents;
    } catch(error){
        console.log("SAGA:willGetStudentsByTeacherCodefails error ", error)
        return null;
    }
}


function* willGetRegisteredUsers(action: any):any {
    try {
        const forceReload = action.payload;
        let result = null;
        if (forceReload !== true) result = yield select(UsersSelector.getRegisteredUsers);
        // se nello store non ci sono ancora gli utenti registrati oppure sto forzando
        // il caricamento, interrogo il backend e richiedo il caricamento del profilo
        // degli utenti (operazione particolarmente onerosa lato backend,
        //dell'ordine di 30 secondi per 200 utenti)
        if (result == null || result.length < 1) {
            result = (yield call(getRegisteredUsers));
            //console.log("SAGA willGetRegisteredUsers result:", result);
            yield put(UsersActions.didGetRegisteredUsers(result));
        }

        
    } catch (error) {
        //console.log("SAGA: willGetRegisteredUsers fails error ", error)
        throw error
    }
}

export function* willGetUser(action: any):any {
    const userId = action.payload;
    //console.log("SAGA: willGetUser on:", userId);
    // PERCHE' C'ERA QUESTA ISTRUZIONE? -> per rimuovere il valore precedente....
    yield put(UsersActions.setCurrentUserData(null)); 
    try {

        if (userId == null || userId == "CODE_UNBOOKED" || userId == "") {
            //console.log("DDDD SAGA: willGetUser imposto a null setCurrentUserData");
            yield put(UsersActions.setCurrentUserData(null));
            return null;
        }
        // Verifico che le informazioni dell'utente non siano già
        // disponibili nello store. In tal caso mi limito ad aggiornare
        // il currentUserData
        const userDataFromStore = yield select(UsersSelector.getUser(userId));
        if (userDataFromStore != null) {
            //console.log("DDDD SAGA graphql: willGetUser Utente trovato:", userDataFromStore);
            yield put(UsersActions.setCurrentUserData(userDataFromStore));
            return userDataFromStore;
        }

        const result = yield call(getUser,
            userId
        );
        const userData = result["data"]["getUser"];
        //console.log("DDDD SAGA graphql: willGetUser:", userData);

        yield put(UsersActions.didGetUser(userData));
        yield put(UsersActions.setCurrentUserData(userData));
        return userData

    } catch (error) {

        //console.log("SAGA: willGetUser fails error ", error)
        throw error
    }
}