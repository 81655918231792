import { API, graphqlOperation } from 'aws-amplify';
import { loader } from 'graphql.macro';

export const createRemoteSession = async (lab: String,
  note: String,
  startDate: any,
  title: String,
  stopDate: any,
  language: String,
  type: String,
  maxBookings: any) => {

  const query = loader('../graphql/createRemoteSession.gql');
  console.log('createRemoteSession Mutation: ', query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { lab, note, startDate, title, stopDate, language, type, maxBookings }
    });
    return result

  } catch (error) {
    console.log("Error in createRemoteSession Mutation", error)
    throw error
  }
}

export const updateRemoteSession = async (id: String,
  lab: String,
  note: String,
  startDate: any,
  title: String,
  stopDate: any,
  language: String,
  maxBookings: any) => {

  const query = loader('../graphql/updateRemoteSession.gql');
  //console.log('updateRemoteSession mutation: ', mutation)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { id, lab, note, startDate, title, stopDate, language, maxBookings }
    });
    return result

  } catch (error) {
    console.log("Error in updateRemoteSession Mutation", error)
    throw error
  }
}

export const putGrantedAttendeesInRemoteSession = async (
  organization: String, 
  id: String,
  reservedTo: any,
  reservedToSardinianTeachers : Boolean
 ) => {

  const query = loader('../graphql/putGrantedAttendeesInRemoteSession.gql');
  //console.log('putGrantedAttendeesInRemoteSessionn query: ', query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {organization, id, reservedTo, reservedToSardinianTeachers  }
    });
    return result

  } catch (error) {
    //console.log("Error in putGrantedAttendeesInRemoteSession Query", error)
    throw error
  }
}

export const putHomeworkUrlInRemoteSession = async (
  organization: String, 
  id: String,
  homeworkUrl: String
 ) => {

  const query = loader('../graphql/putHomeworkUrlInRemoteSession.gql');
  //console.log('putHomeworkUrlInRemoteSession query: ', query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: {organization, id, homeworkUrl  }
    });
    return result

  } catch (error) {
    console.log("Error in putHomeworkUrlInRemoteSession Query", error)
    throw error
  }
}

export const getRemoteSession = async (organization:String, id: String) => {

  const query = loader('../graphql/getRemoteSession.gql');
  //console.log('getRemoteSession query: ', query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { organization, id }
    });
    //console.log('getRemoteSession result: ', result)
    return result

  } catch (error) {
    //console.log("Error in getRemoteSession Query", error)
    throw error
  }
}

export const deleteRemoteSession = async (id: String) => {

  const query = loader('../graphql/deleteRemoteSession.gql');
  //console.log('deleteRemoteSession query: ', query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { id }
    });
    //console.log('deleteRemoteSession result: ', result)
    return result

  } catch (error) {
    //console.log("Error in deleteRemoteSession Query", error)
    throw error
  }
}



export const listBookedSessions = async (
  stopDate: any) => {

  const query = loader('../graphql/listBookedSessions.gql');
  //console.log('listBookedSessions query: ', query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { stopDate }
    });
    const appointments = result["data"]["listBookedSessions"];
    //console.log("listBookedSessions result:", appointments);
    return appointments
  } catch (error) {
    //console.log("Error in listBookedSession Query", error)
    throw error
  }
}

export const listBookingsByRemoteSession = async (
  id: any) => {

  const query = loader('../graphql/listBookingsByRemoteSession.gql');
  //console.log('listBookingsByRemoteSession query: ', query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { id }
    });
    const appointments = result["data"]["listBookingsByRemoteSession"];
    //console.log("listBookingsByRemoteSession result:", appointments);
    return appointments

  } catch (error) {
    console.log("Error in listBookingsByRemoteSession Query->", error)
    throw error
  }
}

export const listRemoteSessions = async (
  startDate: any,
  stopDate: any) => {

  const query = loader('../graphql/listRemoteSessions.gql');
  //console.log('listRemoteSessions query: ', query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { startDate, stopDate }
    });
    const appointments = result["data"]["listRemoteSessions"];
    //console.log("listRemoteSessions result:", appointments);
    return appointments

  } catch (error) {
    console.log("Error in listRemoteSession Query->", error)
    throw error
  }
}

export const getSessionDirectors = async (session:any
  ) => {
  
    const query = loader('../graphql/getSessionDirectors.gql');
    ////console.log('getSessionDirectors query: ', query)
    try {
      const results: any = await API.graphql({
        query: query,
        variables: {session}
      });
       ;
       const sessionDirectors = results["data"]["getSessionDirectors"] 
      ////console.log(`getSessionDirectors SAGA result: ${session}`,  sessionDirectors);
      return sessionDirectors;
  
    } catch (error) {
      //console.log("Error in getSessionDirectors Query", error)
      throw error
    }
  }

  export const updateSessionDirectors = async (session:any, directors:any
    ) => {
      const query = loader('../graphql/putSessionDirectors.gql');
      const directorsParam = directors.map((o:any)=> { return {"director": o["director"]}})
      //console.log('putSessionDirectors query, sessionId, directors: ', query, session, directorsParam)
      try {
        const results: any = await API.graphql({
          query: query,
          variables: {"session": session, "directors" :directorsParam}
        });
        //console.log("putSessionDirectors SAGA result:", results);
      } catch (error) {
        //console.log("Error in updateSessionDirectors Query", error)
        throw error
      }
    }
  

export const listDirectors = async (
) => {

  const query = loader('../graphql/listDirectors.gql');
  //console.log('listDirectors query: ', query)
  try {
    const results: any = await API.graphql({
      query: query
    });
     ;
     const directors = results["data"]["listDirectors"] 
    //console.log("listDirectors SAGA result:::", directors);
    return directors;

  } catch (error) {
    console.error("Error in listDirectors Query", error)
    throw error
  }
}


export const listInteractiveDevices = async (
  ) => {
  
    const query = loader('../graphql/listInteractiveDevices.gql');
    //console.log('listInteractiveDevices query: ', query)
    try {
      const results: any = await API.graphql({
        query: query
      });
       ;
       const interactiveDevices = results["data"]["listInteractiveDevices"] 
      //console.log("listInteractiveDevices SAGA result:::", interactiveDevices);
      return interactiveDevices;
  
    } catch (error) {
      console.error("Error in listInteractiveDevices Query", error)
      throw error
    }
  }

export const listRemoteSessionsByTitle = async (
  title: any,
) => {

  const query = loader('../graphql/listRemoteSessionsByTitle.gql');
  //console.log('listRemoteSessionsByTitle query: ', query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { title }
    });
    const appointments = result["data"]["listRemoteSessionsByTitle"];
    //console.log("listRemoteSessionsByTitle result:", appointments);
    return appointments

  } catch (error) {
    console.error("Error in listRemoteSessionByTitle Query", error)
    throw error
  }
}

export const getResourcesForSession = async (
  session: any,
) => {

  const query = loader('../graphql/getResourcesForSession.gql');
  //console.log('getResourcesForSession query: ', query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { session }
    });
    const resources = result["data"]["getResourcesForSession"];
    //console.log("getResourcesForSession result:", resources);
    return resources

  } catch (error) {
    //console.log("Error in getResourcesForSession Query", error)
    throw error
  }
}


export const bookRemoteSession = async (organization: String, id: String, school: String,
  schoolGrade: String, schoolLevel: String, schoolSection: String, 
  country:String, minAge: any, maxAge:any, language: any, 
  contest2024: Boolean, schoolMatter: String, learningObjectives: String) => {

const query = loader('../graphql/bookMultiRemoteSession.gql');
console.log('bookMultiRemoteSession mutation: ', query)
try {
const result: any = await API.graphql({
query: query,
variables: { organization, id, school, schoolGrade, schoolLevel, schoolSection,
              country, minAge, maxAge, language,
              contest2024, schoolMatter, learningObjectives }
});
return result

} catch (error) {
console.log("Error in bookMultiRemoteSession Mutation", error)
throw error
}
}


export const startStopEC2byInteractiveDeviceId = async (deviceId: String, action: String) => {

const query = loader('../graphql/startStopEC2byInteractiveDeviceId.gql');
//console.log(`startStopEC2byInteractiveDeviceId device:${deviceId} action: ${action} mutation: `, query)
try {
const result: any = await API.graphql({
query: query,
variables: { deviceId, action }
});
return result

} catch (error) {
//console.log("Error in startStopEC2byInteractiveDeviceId Mutation", error)
throw error
}
}

export const bookInteractiveSession = async (organization: String, id: String, school: String,
  schoolGrade: String, schoolLevel: String, schoolSection: String,
  country: String, minAge: any, maxAge: any) => {

const query = loader('../graphql/bookInteractiveSession.gql');
//console.log('bookInteractiveSession mutation: ', query)
try {
const result: any = await API.graphql({
query: query,
variables: { organization, id, school, schoolGrade, schoolLevel, schoolSection,
            country, minAge, maxAge }
});
return result

} catch (error) {
console.log("Error in bookInteractiveSession Mutation", error)
throw error
}
}


export const unbookRemoteSession = async (organization: String, id: String) => {

  const query = loader('../graphql/unbookMultiRemoteSession.gql');
  console.log('unbookMultiRemoteSession mutation: ', query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { organization, id }
    });
    return result

  } catch (error) {
    console.log("Error in unbookMultiRemoteSession Mutation", error)
    throw error
  }
}

export const unbookRemoteSessionByEditor = async (organization: String, id: String, attendeeId: String) => {

  const query = loader('../graphql/unbookMultiRemoteSessionByEditor.gql');
  console.log(`unbookMultiRemoteSessionByEditor organization: ${organization}: appointId:${id} attendee:${attendeeId} mutation: `, query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { organization, id, attendeeId }
    });
    return result

  } catch (error) {
    console.log("Error in unbookMultiRemoteSessionByEditor Mutation", error)
    throw error
  }
}


export const unbookInteractiveSession = async (organization: String, id: String) => {

  const query = loader('../graphql/unbookInteractiveSession.gql');
  //console.log('unbookInteractiveSession mutation: ', query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { organization, id }
    });
    return result

  } catch (error) {
    //console.log("Error in unbookInteractiveSession Mutation", error)
    throw error
  }
}

export const getUser = async (id: String) => {

  const query = loader('../graphql/getUser.gql');
  //console.log('getUser query: ', query)
  try {
    const result: any = await API.graphql({
      query: query,
      variables: { id }
    });
    return result

  } catch (error) {
    //console.log("Error in getUser Query", error)
    throw error
  }
}
