import { useEffect, useState, useRef } from "react"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectors as UserSelector } from '../../store/slices/users'
import { Contest2024TraceAnalyzer } from "./Contest2024TraceAnalyzer";
import { GrView } from "react-icons/gr";
import ReactJson from 'react-json-view'

import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";


export const ContestStudentsAnalyzer = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation('frontend', { useSuspense: false });
    const [studentSessionRows, setStudentSessionRows] = useState([])
    const users = useSelector(UserSelector.getUsers2)
    const [currentContest2024Session, setCurrentContest2024Session] = useState(null);

    useEffect(() => {
        if (users) {
            console.log("schoolData:", props.schoolData)
            const studentsData = props.schema[props.schoolData["id"]][props.timelineData["id"]];

            console.log("studentsData:", studentsData)
            setStudentSessionRows(
                Object.keys(studentsData).map((userId) => {
                    console.log("UserId:", userId, users[userId]);
                    const sessions = studentsData[userId]
                    const studentRecord = { ...users[userId], sessions }
                    return studentRecord
                }))
        }
    }, [users])


    useEffect(() => {
        console.log("studentSessionRows:", studentSessionRows)
    }, [studentSessionRows])

    const paginationOptions = {
        sizePerPageList: [{
            text: `5 ${t("sessioni")}`, value: 5
        }, {
            text: `10 ${t("sessioni")}`, value: 10
        },
        {
            text: t('Tutte le sessioni'), value: (studentSessionRows ? studentSessionRows.length : 100)
        }
        ], pageStartIndex: 1
    }

    const sessionOverviewFormatter = (cell, row) => {
        console.log("SCHEMA::", props.schoolData)
        const timelineInfo = props.schoolData["tracesOverview"][props.timelineData["id"]]["students"][row.id]
        if (timelineInfo!=null)
        return (<Contest2024TraceAnalyzer
            timelineInfo={timelineInfo} />)
        else return (<p>
            {t("contest2024_punteggio_non_calcolabile_studente")}
        </p>)
    }

    const detailsFormatter = (cell, row) => {
        const studentsData = props.schema[props.schoolData["id"]][props.timelineData["id"]];
        const studentActions = studentsData[row["id"]]
        const studentTrace = { "actions": studentActions }
        return (
            <div style={{display:"flex" , justifyContent:"center"}}>
                <GrView data-for="contest2024Session" data-tip={t("Dettagli sessione")} cursor="pointer"
                    style={{ margin: "10px" }} color="#007bff" onClick={() => {
                        console.log("detailsFormatter row", row);
                        setCurrentContest2024Session({"row" : row, "trace" : studentsData[row["id"]][0]})
                    }}>
                </GrView>
            </div>

        )

    }
    const numSessionsFormatter = (cell, row) => {

        return `${row["sessions"].length}`
    }

    const fullnameFormatter = (cell, row) => {

        return `${row["family_name"]} ${row["given_name"]}`
    }

    const studentSessionColumns = [
        {
            dataField: 'id',
            text: t("id"),
            sort: true,
            hidden: true
        },
        {
            dataField: 'full_name',
            text: t("Studente"),
            formatter: fullnameFormatter,
            sort: true
        },

        {
            dataField: 'email',
            text: t("Email"),
            sort: true
        },
        {
            dataField: 'numSessions',
            text: t("Totale sessioni"),
            formatter: numSessionsFormatter,
            hidden: true,
            sort: true
        },
        {
            dataField: 'overview',
            text: t("Riepilogo"),
            formatter: sessionOverviewFormatter,
            sort: true
        },
        {
            dataField: 'details',
            text: t("Dettagli"),
            formatter: detailsFormatter,
            sort: false,
            headerStyle: () => {
                return { width: '8%' };
            },
        }
    ]

    const renderSessionViewerModal = () => {

        return (currentContest2024Session && <Modal isOpen={currentContest2024Session != null}>
            <ModalHeader style={{
                display: "flex",
                justifyContent: "flex-start",
            }}><b>{t("Timeline")}:</b>{` ${props.timelineData["id"]}`} <br></br>
                <b>{t("Studente")}:</b>{` ${currentContest2024Session["row"]["family_name"]} ${currentContest2024Session["row"]["given_name"]}`}
            </ModalHeader>

            <ModalBody>
                <ReactJson style={{ padding: "10px" }} theme="monokai" displayDataTypes={false} collapsed={3}
                    src={currentContest2024Session["trace"]} />
            </ModalBody>
            <ModalFooter style={{
                display: "flex",
                justifyContent: "right",
            }}>



                <Button color="primary" onClick={() => {
                    setCurrentContest2024Session(null);
                }}>Chiudi</Button>

            </ModalFooter>
        </Modal>)
    }


    return <div>
        {renderSessionViewerModal()}
        <BootstrapTable bootstrap4
            key={`${i18n.language}_${props.cskey}`} 
            keyField='id' data={studentSessionRows || []}
            columns={studentSessionColumns}
            pagination={paginationFactory(paginationOptions)} />
    </div>
}