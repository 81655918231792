import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { selectors as ProfileSelectors } from '../store/slices/profile'
import { useEffect, useState } from 'react'
import { actions as UsersActions, selectors as UsersSelector } from '../store/slices/users'
import { FaCopy } from 'react-icons/fa';
import { TooltipButton } from './ActivityButton';
import { Card, CardText, CardBody, CardHeader, CardTitle } from 'reactstrap';
import StudentsListManager from "./Contest2024/StudentsManager"

const TeacherClassroom = (props: any) => {
    
    const userProfile = useSelector(ProfileSelectors.getProfile)
    const { t } = useTranslation('frontend', { useSuspense: false });

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ "fontSize": "20px", "margin": "20px", "display": "flex", "justifyContent": "flex-start" }}>
                <b>{`${t("Codice Docente")}:`}&nbsp;</b>{userProfile?.sub}
                <TooltipButton duration={2500}
                    tooltipMessage={`${t("Copia il codice docente")}`}
                    onClickMessage={t('Copiato')}
                    onChildClick={async () => {
                        await navigator.clipboard.writeText(userProfile?.sub);
                    }}
                >
                    <FaCopy cursor="pointer" fontSize={"20px"}
                        style={{ marginLeft: "5px", marginRight: "5px", marginTop: "5px", border: '1px solid #007bff"', }} color="#007bff" />
                </TooltipButton>
            </div>
            <Card className="m-2" style={{
                
                borderColor: "#007bff"
            }}>
                <CardHeader style={{
                    backgroundColor: "#007bff",
                    borderColor: "#007bff",
                    paddingBottom: 0,
                    color: 'white'

                }}>
                    <CardTitle tag="h5">{`${t("I miei studenti")}`}</CardTitle>
                </CardHeader>
                <CardBody>
                   <StudentsListManager/>
                </CardBody>
            </Card>
        </div>
    )
}

export default TeacherClassroom;