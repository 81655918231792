import { useState, useEffect } from 'react';
import { Container, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import NumberPicker from "react-widgets/NumberPicker";
import { useTranslation } from 'react-i18next';
import { schoolLevels, LivelliScolasticiItaliani } from '../../pages/constants';

export const ItalianSchoolLevelAndGradeSelector = (props) => {

  const { t } = useTranslation('frontend', { useSuspense: false });
  const [level, setLevel] = useState(props.level);
  const [grade, setGrade] = useState(props.grade || "1");
  const [section, setSection] = useState(props.section);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [isValid, setIsValid] = useState(props.section!=null && props.section.length>0);

  const getAgeRange = (level, grade) => {
    if (level==null || level=="") return {"minAge" : null , "maxAge" : null};
    if (level == LivelliScolasticiItaliani.MEDIE)
      return { "minAge": 10 + parseInt(grade), "maxAge": 11 + parseInt(grade) }
    else return { "minAge": 13 + parseInt(grade), "maxAge": 14 + parseInt(grade) }
  }

  const getLevelOptions = () => {
    const options = [<option  style={{color:"black"}} key={""} value={""}>{t("Specifica il grado scolastico")}</option>]
    
    options.push(... Object.entries(schoolLevels).map(
      (e, i) => <option  style={{color:"black"}} key={e[0]} value={e[0]}>{t(e[1])}</option>))

    return options;
  }

  const getGradeOptions = (level) => {
    let options = []
    if(level == "1") 
      options =  [["1", "I"], ["2", "II"], ["3", "III"]] 
    else if (level=="2")
      options = [["1", "I"], ["2", "II"], ["3", "III"], ["4", "IV"], ["5", "V"]]
    return options.map(
      (e, i) => <option key={e[0]} value={e[0]}>{t(e[1])}</option>);
  }

  useEffect(() => {
    if (props.level != null) {
      updateLevel(props.level);
    }
    else updateLevel("");


    if (props.level=="")  {
      console.log("DEB SECTION: level stringa vuota");
      setSection("") 
    };

  }, [props.level])


  useEffect(()=>{
    setSection(props.section);
  }, [props.section])

  useEffect(() => {
    const newGradeOptions = getGradeOptions(level)
    if (level == LivelliScolasticiItaliani.MEDIE && grade > 3) {
      setGrade("1")
    }
    setGradeOptions(newGradeOptions);
  }, [level])

  useEffect(()=>{
    setIsValid(section!=null && section.trim() !== ''); 
  },[section])

  useEffect(() => {
    //console.log(`AF: useEffect level: ${level}, grade:${grade}, section:${section}`)
    if (level != null && grade != null) {
      props.onChange && props.onChange(

        { 
          "errors" : !isValid,
          "schoolLevel": level, "schoolGrade": grade, "schoolSection": section,
          ...getAgeRange(level, grade), "country": "IT"
        }
      )
    }

  }, [level, grade, section, isValid])

  const updateLevel = (newLevel) => {
    const selectedLevel = newLevel;
    setLevel(selectedLevel);
  }

  const updateGrade = (newGrade) => {
    setGrade(newGrade);
  }


  const handleLevelChange = (event) => {
    updateLevel(event.target.value);
  };

  const handleGradeChange = (event) => {
    updateGrade(event.target.value);
  };

  const handleSectionChange = (event) => {
    setSection(event.target.value);
  };

  return (
    <Row form>
      <Col md={7}>
        <FormGroup>
          <b><i>
            <Label for="level">{t("Grado scolastico")}</Label>
          </i></b>
          <Input type="select" name="level" id="level"
            style={{color: !level ? "red" : "black"}} 
            disabled={props.disabled}
            value={level} onChange={handleLevelChange}>
            {getLevelOptions()}
          </Input>
        </FormGroup>
      </Col>
      <Col md={3}>
        <FormGroup>
          <b><i>
            <Label style={{color: (grade=="" ? "red": "black")}} for="grade">{t("Classe")}</Label>
          </i></b>
          <Input type="select" name="grade" id="grade"
            disabled={props.disabled}
            value={grade}
            onChange={handleGradeChange}>
            {gradeOptions.map((gradeOption, index) => (
              gradeOption
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col md={2}>
        <FormGroup>
          <b><i>
            <Label style={{color: (!isValid ? "red": "black")}} for="classSection">{t("Sezione")}</Label>
          </i></b>
          <Input type="text" disabled={props.disabled}
          style={{ borderColor: !isValid ? 'red' : '#ced4da' }} 
          value={section} onChange={handleSectionChange} name="classSection" id="classSection">
          </Input>
        </FormGroup>
      </Col>
    </Row>
  );
};

export const ForeignSchoolSelector = (props) => {
  
  const [school, setSchool] = useState(props.school || "")
  
  useEffect(()=>{
    props.onChange && props.onChange(school)
  }, [school])

  return (<Input type='text'  style={{ borderColor: (school.trim()=="")? 'red' : '#ced4da' }} 
  onChange = {(event)=>{
    setSchool(event.target.value)
  }}
  value={school} disabled={props.disabled}/>)
}

export const ForeignSchoolGradeSelector = (props) => {

  const { t } = useTranslation('frontend', { useSuspense: false });
  const [minAge, setMinAge] = useState(props.minAge || 16);
  const [maxAge, setMaxAge] = useState(props.maxAge || 17);

  useEffect(() => {
    props.onChange && props.onChange({ "minAge": minAge, "maxAge": maxAge, 
    "schoolLevel": "", "schoolGrade": "", "schoolSection": "", 
    "country" : props.country || "",
    "errors": (minAge > maxAge) })
  }, [minAge, maxAge])
  return (
    <Container style={{ padding: "5px" }}>
      <Row>
        <Col xs="6">
          <Label for="minAgePicker"><b>{t("minAge")}</b></Label>
          <NumberPicker
           style={{ borderColor: (minAge>maxAge) ? 'red' : '#ced4da' }} 
            id="minAgePicker"
            disabled={props.disabled}
            value={minAge}
            inputProps={{ "style": { "color": `${minAge <= maxAge ? "black" : "red"}` } }}
            onChange={value => setMinAge(value)}
            min={11}
            max={20}
          />
        </Col>
        <Col xs="6">
          <Label for="maxAgePicker"><b>{t("maxAge")}</b></Label>
          <NumberPicker
            id="maxAgePicker"
            style={{ borderColor: (minAge>maxAge) ? 'red' : '#ced4da' }} 
            disabled={props.disabled}
            value={maxAge}
            inputProps={{ "style": { "color": `${minAge <= maxAge ? "black" : "red"}` } }}
            onChange={value => setMaxAge(value)}
            min={11}
            max={20}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ItalianSchoolLevelAndGradeSelector;
