import { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import StudentTabs, { findStudentByEmail } from "../Dashboards/StudentsTabs";
import RialeAccordion from '../RialeAccordion';
import {
    Container, Row, Col,
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";

import ItalianSchoolLevelAndGradeSelector from "../Calendar/SchoolGradeSelector";
import { useDispatch, useSelector } from 'react-redux';
import { selectors as AuthSelectors, actions as AuthActions } from '../../store/slices/auth'
import useStudents from '../useStudents';

const StudentsListManager = () => {
    const classFullName = useRef(null);
    const classData = useRef(null);
    const [email, setEmail] = useState("")
    const [cognome, setCognome] = useState("")
    const [nome, setNome] = useState("")
    const [isContest2024, setContest2024] = useState(true)
    const [classesData, setClassesData] = useState({});
    const { t } = useTranslation('frontend', { useSuspense: false });
    const dispatch = useDispatch()
    const [validationErrors, setValidationErrors] = useState({ classFullName: "Valore della sezione non specificato" });
    const [isDataModified, setIsDataModified] = useState(false); // Stato per tracciare se ci sono modifiche
    const { allowedStudents, isEmailRegisteredWithMe } = useStudents();

    const restoreChanges = () => {
        setClassesData(allowedStudents);
        setIsDataModified(false);
    }


    useEffect(() => {
        setValidationErrors(validateFields());
    }, [nome, cognome, email])


    useEffect(() => {
        if (allowedStudents != null) {
            //console.log("allowed students in Manager:", allowedStudents)
            restoreChanges();
        }

    }, [allowedStudents])


    useEffect(() => {
        //console.log("SM: classData:", classesData)
    }, [classesData])


    const updateAllowedStudents = () => {
        dispatch(AuthActions.willUpdateAllowedStudents(classesData));
    }

    const validateFields = () => {
        const errors = {};

        if (classFullName.current == null) {
            errors.class_fullname = "Valore della sezione della classe non valido"
        }

        // Verifica che Cognome non sia vuoto
        if (!cognome.trim()) {
            errors.family_name = t("Campo obbligatorio");
        }

        // Verifica che Nome non sia vuoto
        if (!nome.trim()) {
            errors.given_name = t("Campo obbligatorio");
        }

        // Verifica che Email sia valida
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.trim()) {
            errors.email = t("Campo obbligatorio");
        } else if (!emailRegex.test(email)) {
            errors.email = t("Formato non valido");
        }
        else if (classesData[classFullName.current]?.some(student => student.email === email)) {
            errors.email = t("L'email specificata è già presente nella classe specificata")
        }
        else if (findStudentByEmail(email, classesData) != null) {
            errors.email = t("La email specificata è stà stata inserita in un'altra classe")
        }

        return errors;
    };

    const renderAddStudentForm = () => {


        const addStudentToClass = () => {
            const validationErrors = validateFields();

            if (Object.keys(validationErrors).length > 0) {
                setValidationErrors(validationErrors);
                return;
            }

            // Se non ci sono errori, aggiungi lo studente e resetta i campi
            setValidationErrors({});
            //console.log("Aggiungo studente:", { cognome, nome, email, isContest2024, className: classFullName.current });

            // Logica per aggiungere lo studente alla classe...
            // Aggiorna i dati
            setClassesData((prevData) => {
                const updatedData = { ...prevData };
                if (!updatedData[classFullName.current]) {
                    updatedData[classFullName.current] = [];
                }
                updatedData[classFullName.current].push({
                    "email": email,
                    "isRegistered": isEmailRegisteredWithMe(email),
                    "contest2024": isContest2024,
                    "given_name": nome,
                    "family_name": cognome,
                    "schoolGrade": classData.current.schoolGrade,
                    "schoolLevel": classData.current.schoolLevel,
                    "schoolSection": classData.current.schoolSection,
                });
                return updatedData;
            });

            // Resetta i campi
            setEmail("");
            setNome("");
            setCognome("");
            setIsDataModified(true);
        };


        return (
            <div style={{ marginTop: "10px", marginBottom: "10px", padding: "10px", "border": "1px solid black" }}>
                <Row>
                    <Col md={6}>
                        <ItalianSchoolLevelAndGradeSelector
                            level={2}
                            grade={1}
                            section={""}
                            disabled={false}
                            onChange={(value) => {
                                //console.log("SELECTION;", value);
                                if (value.errors === true) {
                                    classFullName.current = null;
                                    classData.current = null;
                                    setValidationErrors(validateFields());
                                } else {
                                    classData.current = value;
                                    classFullName.current = `${value.schoolGrade}${value.schoolSection.toLowerCase()}`;
                                    setValidationErrors(validateFields());
                                }
                            }}
                        />
                    </Col>
                    {/*
                        <Col md={2} className="text-center">
                        <br />
                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0" check>
                            <Input
                                id="contest2024"
                                type="checkbox"
                                value={isContest2024}
                                onChange={(e) => setContest2024(e.target.checked)}
                            />
                            {' '}
                            <Label for="contest2024">
                                {t("Contest 2024")}
                            </Label>
                        </FormGroup>
                    </Col>
                    
                    */}

                </Row>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <b><i>
                                <Label for="family_name">{t("Cognome")}</Label>
                            </i></b>
                            <Input
                                id="family_name"
                                type="text"
                                placeholder=""
                                value={cognome}
                                onChange={(e) => setCognome(e.target.value)}
                            />
                            {validationErrors.family_name && (
                                <div className="text-danger">{validationErrors.family_name}</div>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <b><i>
                                <Label for="given_name">{t("Nome")}</Label>
                            </i></b>
                            <Input
                                id="given_name"
                                type="text"
                                placeholder=""
                                value={nome}
                                onChange={(e) => setNome(e.target.value)}
                            />
                            {validationErrors.given_name && (
                                <div className="text-danger">{validationErrors.given_name}</div>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={5}>
                        <FormGroup>
                            <b><i>
                                <Label for="email">{t("Email")}</Label>
                            </i></b>
                            <Input
                                id="email"
                                type="email"
                                placeholder="studente@email.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {validationErrors.email && (
                                <div className="text-danger">{validationErrors.email}</div>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={1}>
                        <Button color="primary" className="ms-3"
                            disabled={Object.keys(validationErrors).length > 0}
                            onClick={addStudentToClass}>
                            {t("Aggiungi")}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    };


    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <StudentTabs initialData={classesData}
                isDataModified={isDataModified}
                onSaveRequest={() => updateAllowedStudents()}
                onRestoreRequest={() => restoreChanges()}
                onDataChanged={(data) => {
                    //console.log("SM: Richiamato onDataChanged")
                    setClassesData(data)
                    setIsDataModified(true);
                }} />
            <RialeAccordion style={{ paddingTop: "10px", marginLeft: "10px", backgroundColor: "#007bff", color: "white", paddingBottom: "10px" }}
                title={` ${t("Iscrivi un nuovo studente")}`}>
                {renderAddStudentForm()}
            </RialeAccordion>

        </div>
    );
};

export default StudentsListManager;
