import MobileLightTimeline from "./LightTimeline2";
import { useTranslation } from 'react-i18next';
import "./LightVideoPlayer.css"
import { Footer } from '../../header';
import {useState, useEffect } from "react";
import { ReadMoreText } from "./ItemEditor";
import withWatcher, { TraceEvent } from './TimelineWatcher';

const InnerMobileTimeline = (props) => {
    const { experiment, activeTab, startDateTime, disabled, hideTitle} = props;
    const [isLookingForLite , setLookingForLite] = useState(true)
    const [firstLiteSectionIndex, setFirstLiteSectionIndex] = useState(null);
    const { t } = useTranslation('tl', { useSuspense: false });

    const getFirstLiteSectionIndex = () => {
        for(let i=0; i< experiment.sections.length;i++)
           {
            if (experiment.sections[i].isLite===true) return i;
           }  
           
           return null;
       }
    
      useEffect(()=>{
        if (experiment!=null)
        {   
            console.log("timeline experiment:", experiment);
            const liteVersionIndex = activeTab || getFirstLiteSectionIndex()
            setFirstLiteSectionIndex(liteVersionIndex)
            setLookingForLite(false)
        }
         
      },[experiment])

      useEffect(()=>{
        console.log(`firstLiteSectionIndex ACTIVE TAB:${activeTab}->`,firstLiteSectionIndex);
      }, [firstLiteSectionIndex])

    if (firstLiteSectionIndex==null) return (<p><i>{`${isLookingForLite ? 
        t("searching for mobile version...") : t("No mobile version found")}`}</i></p>) 
    else
        return(
        <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
        {!hideTitle && (
            <>
             <center>
             <div style={{margin:"10px"}}><h3>{experiment.title}</h3></div>
             </center>
             <center>
                 <span style={{fontSize:"0.8em"}}>{`(${experiment.sections[firstLiteSectionIndex]["title"]})`}</span>
             </center>
             
             <div style={{margin:"10px"}}>
              <ReadMoreText text={experiment.description}
               maxLines={3}
               lineHeight={20}/>
             </div>
            </>
        )}
           
        <MobileLightTimeline 
            id={experiment.experiment}
            startDateTime={startDateTime} disabled={disabled}
            onEventToWatch = {props.onEventToWatch}
            mobileSection={experiment.sections[firstLiteSectionIndex]} />
        {!props.footerHidden && (
            <Footer/>
        )}
           
        </div>
       
    )

}
const MobileTimeline = withWatcher((InnerMobileTimeline));

export default MobileTimeline;